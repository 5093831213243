import { FC, useCallback, useMemo, useState } from 'react';
import { ModuleDefaultConfiguration } from '../../models/ClientModuleDefaults';
import { AccessTypeKeys } from '../../models/Access';
import AccessDropdown from '../shared/AccessDropdown';
import { useTranslation } from 'react-i18next';
import { ClientFormTag, ClientFormUser, clientFormUsersToRequestDto } from '../../models/ClientFormUser';
import AccessControl from '../ownership/AccessControl';
import User from '../../models/User';
import UserInvitation from '../../models/UserInvitation';
import { useManageDocumentClassesWizard } from '../../contexts/ManageDocumentClassesContext';
import { useCreateDocumentWizard } from '../../contexts/CreateDocumentContext';
import { useCurrentClient } from '../../global-state/Clients';

type CreateAccessProps = {
  defaults: ModuleDefaultConfiguration;
  onDefaultsChange: (defaults: ModuleDefaultConfiguration | ((prev: ModuleDefaultConfiguration) => ModuleDefaultConfiguration)) => void;
  clientUsers: User[];
  requireValidator?: boolean;
  requireApprover?: boolean;
  requireOwner?: boolean;
  inviteUserMode?: boolean;
  onUserInviteChange?: (user: UserInvitation) => void;
  onUserInviteValid?: (isValid: boolean) => void;
  modalIsValid: boolean;
};

const ManageAccess: FC<CreateAccessProps> = (props) => {
  const {
    defaults,
    onDefaultsChange,
    clientUsers,
    requireValidator,
    requireApprover,
    requireOwner,
    inviteUserMode,
    onUserInviteChange,
    onUserInviteValid,
    modalIsValid,
  } = props;
  const { t } = useTranslation(['documents']);
  const currentClient = useCurrentClient((x) => x.value);
  const { selectedClass, setSelectedClass, newTemplate, setTemplate } = useManageDocumentClassesWizard() ?? {};
  const { newDocument, classes } = useCreateDocumentWizard() ?? {};

  const configValueChange = useCallback(
    <TKey extends keyof ModuleDefaultConfiguration>(key: TKey, value: ModuleDefaultConfiguration[TKey]) => {
      onDefaultsChange((prev) => ({ ...prev, [key]: value }));
    },
    [onDefaultsChange],
  );

  const assignedUsers = useMemo((): ClientFormUser[] => {
    if (defaults && clientUsers.length) {
      const users = (defaults?.users || [])
        .flatMap((x) =>
          x.users.map((u) => {
            const clientUser = clientUsers.find((user) => user.id && user.id === u.userId)!;
            if (clientUser) return { ...clientUser, ...u, role: x.role };

            return null;
          }),
        )
        .filter(Boolean);

      return users;
    }
    return [];
  }, [clientUsers, defaults]);

  const assignedTags = useMemo(() => {
    return (
      defaults?.users?.flatMap((x) =>
        x.tags.map((t) => ({
          ...t,
          role: x.role,
          users: defaults.users
            ?.filter((u) => u.tags.some((ut) => ut.id === t.id))
            .flatMap((u) => u.users.map((uu) => clientUsers.find((cu) => cu.id === uu.userId)))
            .filter(Boolean),
        })),
      ) ?? []
    );
  }, [clientUsers, defaults.users]);

  const onUsersChange = useCallback(
    (users: ClientFormUser[]) => {
      configValueChange('users', clientFormUsersToRequestDto(users, assignedTags));
    },
    [assignedTags, configValueChange],
  );

  const onTagsChange = useCallback(
    (tags: ClientFormTag[], users: ClientFormUser[]) => {
      configValueChange('users', clientFormUsersToRequestDto(users, tags));
    },
    [configValueChange],
  );

  const [createDocumentOwnerRequiresAction, setCreateDocumentOwnerRequiresAction] = useState(
    classes?.find((x) => x.clientModuleId === newDocument?.clientModuleId)?.templateModule?.requiresOwnerAcknowledgement,
  );

  return (
    <>
      {!inviteUserMode && (
        <>
          <div className="relative py-4">
            <AccessDropdown selectedAccess={defaults.accessType} onChange={(value) => configValueChange('accessType', value)} />
            <div className="pl-6">{t(AccessTypeKeys[defaults.accessType], { client: currentClient?.name })}</div>
          </div>
          <div className="text-dpm-16 pb-4 font-medium">{t('access.default-members')}</div>
        </>
      )}
      <AccessControl
        users={assignedUsers}
        tags={assignedTags}
        onTagsChange={onTagsChange}
        onUsersChange={onUsersChange}
        requireValidator={requireValidator}
        requireApprover={requireApprover}
        requireOwner={requireOwner}
        inviteMode={inviteUserMode}
        onUserInviteChange={onUserInviteChange}
        onUserInviteValid={onUserInviteValid}
        modalIsValid={modalIsValid}
        ownerRequiresAction={
          // Creating a new document
          createDocumentOwnerRequiresAction ||
          // Creating a new class
          newTemplate?.requiresOwnerAcknowledgement ||
          // Modifying an existing class
          selectedClass?.templateModule.requiresOwnerAcknowledgement
        }
        onOwnerRequiresActionChange={(value) => {
          setTemplate?.((prev) => prev && { ...prev, requiresOwnerAcknowledgement: value });
          setSelectedClass?.((prev) => prev && { ...prev, templateModule: { ...prev.templateModule, requiresOwnerAcknowledgement: value } });
          setCreateDocumentOwnerRequiresAction(value);
        }}
      />
    </>
  );
};

export default ManageAccess;
